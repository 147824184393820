////////////////////
// LINKS
////////////////////////////////////////////////////////////////////////////////

// LINK DEFAULT
// ---------------------------------------------------------
.link {
  @apply relative {}
  @apply transition {}
  @apply transition-colors {}
  @apply ease-in {}
  @apply duration-300 {}
  @apply cursor-pointer {} }


// LINK UNDERLINE
// ---------------------------------------------------------
.link-underline {
  @apply inline-block {}
  @extend .link;
  @apply pb-1 {}

  &::after {
    content: '';
    @apply absolute {}
    @apply left-0 {}
    @apply bottom-0 {}
    background-color: currentColor;
    @apply block {}
    @apply w-0 {}
    height: 3px;
    @apply transition {}
    @apply transition-width {}
    @apply ease-in {}
    @apply duration-300 {} }
  &:hover,
  &:focus, {
    &::after {
      @apply w-full {} } } }


// LINK COLOR
// ---------------------------------------------------------

///// LINK PRIMARY
.link-primary {
  @apply text-primary {}
  &:hover,
  &:focus,
  &.active {
    @apply text-primary-100 {} } }

///// LINK WHITE
.link-white {
  @apply text-white {}
  &:hover,
  &:focus,
  &.active {
    @apply text-secondary {} } }

///// LINK GRAY PRIMARY
.link-gray {
  @apply text-gray {}
  &:hover,
  &:focus,
  &.active {
    @apply text-primary {} } }

// FOOTER LINKS
// ---------------------------------------------------------
.footer-links li {
  @screen md {
    &::after {
      content: '-';
      @apply mx-1 {}
      @apply inline-block {} }

    &:last-child::after {
      @apply hidden {} } } }

// BREADCRUMB
// ---------------------------------------------------------
// BREADCRUMB
// ---------------------------------------------------------
.breadcrumb {
  @apply flex {}
  @apply text-xs {}

  &__link {
    @apply mr-2 {}

    a {
      @extend .link; }

    i {
      @apply ml-2 {} }

    &.active {
      @apply font-bold {} }

    &:last-child i {
      @apply hidden {} } } }


.breadcrumb-primary {
  .breadcrumb {
    &__link {
      a {
        @extend .link-primary; }

      i {
        @apply text-primary {} } } } }

.breadcrumb-white {
  .breadcrumb {
    &__link {
      a {
        @extend .link-white; }

      i {
        @apply text-white {} } } } }
