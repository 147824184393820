////////////////////
// BUTTONS
////////////////////////////////////////////////////////////////////////////////

// BUTTON DEFAULT
// ---------------------------------------------------------

///// BUTTON
.btn {
  @apply relative {}
  @apply font-title {}
  @apply text-base {}
  @apply font-bold {}
  @apply text-center {}
  @apply inline-flex {}
  @apply justify-center {}
  @apply items-center {}
  @apply overflow-hidden {}
  @apply box-border {}
  @apply transition {}
  @apply transition-colors {}
  @apply ease-in {}
  @apply duration-300 {}
  @apply leading-none {}

  @screen lg {
    @apply text-lg {} }

  &:focus {
    @apply outline-none {} } }

a.btn,
input.btn,
button.btn {
  @apply cursor-pointer {} }


// BUTTON OUTLINE
// ---------------------------------------------------------
.btn-outline {
  @extend .btn;
  @apply border-2 {} }

// BUTTON COLOR
// ---------------------------------------------------------

///// BUTTON PRIMARY
.btn-primary {
  @apply bg-primary {}
  @apply text-white {} }
@include btnHover('.btn-primary') {
  @apply text-white {}
  @apply bg-primary-300 {} }

.btn-secondary {
  @apply bg-secondary {}
  @apply text-white {} }
@include btnHover('.btn-secondary') {
  @apply text-white {}
  @apply bg-secondary-300 {} }

///// BUTTON GRAY
.btn-gray {
  @apply bg-gray {}
  @apply text-white {} }
@include btnHover('.btn-gray') {
  @apply text-white {}
  @apply bg-darkgray {} }


// BUTTON OUTLINE
// ---------------------------------------------------------
.btn-outline-primary {
  @apply text-primary {}
  @apply border-secondary {} }

@include btnHover('.btn-outline-primary') {
  @apply bg-secondary {} }

.btn-outline-gray {
  @apply text-gray {} }

@include btnHover('.btn-outline-gray') {
  @apply bg-gray {}
  @apply text-white {} }

.btn-outline-white {
  @apply text-white {}
  @apply border-white {} }

@include btnHover('.btn-outline-white') {
  @apply bg-white {}
  @apply text-primary {} }


// BUTTON SIZE
// ---------------------------------------------------------
.btn-lg {
  @apply h-button-mobile {}
  @apply px-6 {}

  @screen lg {
    @apply h-button {}
    @apply px-10 {} } }
