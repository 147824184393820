.line-clamp-1 {
  @include lineClamp(1); }

.line-clamp-2 {
  @include lineClamp(2); }

.line-clamp-3 {
  @include lineClamp(3); }

//Accessibility tricks
.visually-hidden {
  @apply absolute {}
  @apply overflow-hidden {}
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  @apply p-0 {}
  @apply border-0 {} }
