table.tab {
  @apply mb-8 {}
  @apply w-full {}
  @apply text-center {}

  td {
    @apply w-1/2 {}
    @apply py-2 {} }

  tr:nth-child(n) {
    @apply bg-gray-100 {} }

  tr:nth-child(2n) {
    @apply bg-white {} } }
