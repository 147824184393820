////////////////////
// ANIMATION
////////////////////////////////////////////////////////////////////////////////


// ANIM SOLO
// ---------------------------------------------------------
.anim-fade {
  @apply opacity-0 {}
  @apply transition {}
  @apply transition-opacity {}
  @apply duration-smooth {}
  @apply ease-smooth {} }

.anim-up {
  @apply transition {}
  @apply transition-transform {}
  @apply duration-smooth {}
  @apply ease-smooth {} }

.anim-fade-up {
  @apply opacity-0 {}
  transform: translate3d(0,15%,0);
  @apply transition {}
  @apply transition-animation {}
  @apply duration-smooth {}
  @apply ease-smooth {} }

////////////////////
// ANIMATION IN
////////////////////////////////////////////////////////////////////////////////
.in {

  &.anim-fade,
  .anim-fade {
    @apply opacity-100 {} }

  &.anim-up,
  .anim-up, {
    @apply transform-none {} }

  &.anim-fade-up,
  .anim-fade-up, {
    @apply opacity-100 {}
    @apply transform-none {} } }


// CASCAD
// ---------------------------------------------------------
.anim--cascad {
  .anim-fade-up,
  .anim-fade {
    @include transitionDelayUp(250ms, 150ms, 1, 10); } }
