////////////////////
// LAYOUT
////////////////////////////////////////////////////////////////////////////////

// LAYOUT BASE
// ---------------------------------------------------------
body, html {
  @apply w-full {} }

body {
  @apply bg-white {}
  @apply text-gray {}
  @apply text-base {}
  @apply font-sans {}
  @apply antialiased {}
  @apply subpixel-antialiased {} }

////////////////////
// LINKS
////////////////////////////////////////////////////////////////////////////////
a:hover, a:focus {
  @apply no-underline {}
  @apply outline-none {} }
