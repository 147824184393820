// Crée automatiquement les propriétés liées au clampage de textes
@mixin lineClamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  @apply overflow-hidden {}
  text-overflow: ellipsis; }

// Crée automatiquement les placeholders avec les préfixes navigateurs
@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content; }

  &:-moz-placeholder {
    @content; }

  &::-moz-placeholder {
    @content; }

  &:-ms-input-placeholder {
    @content; } }


//Usage : +transitionDelayUp(280ms, 1, 10)
// Arguments: $stepDuration: le délai que l'on veut ajouter
//            $firstStep, $lastStep : les valeurs min/max de la boucle
// Crée des delais de transitions en cascade
@mixin transitionDelayUp($defaultduration, $stepDuration, $firstStep, $lastStep) {

  $currentDuration: $defaultduration;

  @for $i from $firstStep through $lastStep {
    &:nth-child(#{$i}) {
      transition-delay: $currentDuration; }

    $currentDuration: $currentDuration + $stepDuration; } }

//Usage : @include btnHover
// Arguments: btnClass
// btnHover
@mixin btnHover($btnClass) {
  a#{$btnClass}:hover,
  input#{$btnClass}:hover,
  button#{$btnClass}:hover {
    @content; } }

