$pathFont1: '../fonts/raleway/';
$pathFont2: '../fonts/source-sans-pro/';

@mixin declare-font-face($pathFont, $fontFileName, $fontFamilyName, $fontWeight: normal, $fontStyle: normal, $SVGName: '') {
  @font-face {
    font-family: $fontFamilyName;
    font-weight: $fontWeight;
    font-style: $fontStyle;
    font-display: swap;

    src: url('#{$pathFont}#{$fontFileName}.woff2') format('woff2'),
    url('#{$pathFont}#{$fontFileName}.woff') format('woff'), } }



@include declare-font-face($pathFont1, 'Raleway-Bold', 'Raleway', 700, normal);
@include declare-font-face($pathFont1, 'Raleway-Light', 'Raleway', 300, normal);
@include declare-font-face($pathFont1, 'Raleway-Regular', 'Raleway', 400, normal);
@include declare-font-face($pathFont1, 'Raleway-Medium', 'Raleway', 600, normal);

@include declare-font-face($pathFont2, 'SourceSansPro-Bold', 'SourceSansPro', 700, normal);
@include declare-font-face($pathFont2, 'SourceSansPro-Light', 'SourceSansPro', 300, normal);
@include declare-font-face($pathFont2, 'SourceSansPro-Regular', 'SourceSansPro', 400, normal);
@include declare-font-face($pathFont2, 'SourceSansPro-SemiBold', 'SourceSansPro', 600, normal);
