.slider-refuge {
  .swiper-pagination-bullet {
    @apply bg-white #{!important} {}
    @apply h-4 #{!important} {}
    @apply w-4 #{!important} {}
    @apply opacity-100 #{!important} {}
    transform: scale(1) !important;
    border: solid 0.1rem $secondary;

    &-active {
      background-color: $secondary !important; } } }
