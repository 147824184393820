.lg-on {
  @apply overflow-hidden {}
  @apply h-full {}
  @apply max-h-full {} }

.lg-backdrop {
  @apply bg-transparent {} }

.lg-sub-html {
  @apply top-auto {}
  @apply left-0 {}
  @apply right-0 {}
  @apply mx-auto {}
  @apply bottom-0 {}
  @apply text-white {}
  @apply bg-gray-100 {}

  p {
    @apply m-0 {}
    @apply p-0 {} } }

.lg-image {
  z-index: 4000;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25)); }

@screen xl {
  .lg-outer .lg-image {
    height: 85vh !important; } }

.lg-outer {
  @apply bg-white {}
  @apply z-max {} }

#lg-counter {
  @apply hidden {} }

.lg-toolbar {
  @apply bg-transparent {}
  z-index: 6000;

  .lg-icon {
    @apply bg-white {} } }

.lg-actions {
  @apply fixed {}
  bottom: 130px;
  @apply left-0 {}
  @apply right-0 {}
  @apply mx-auto {}
  height: 3.4375rem;
  max-width: 11.25rem;
  z-index: 5000;

  @screen xl {
    bottom: 70px; } }

.lg-close {
  @apply bg-white {}
  @apply text-primary #{!important} {}
  @apply fixed {}
  bottom: 130px;
  @apply left-0 {}
  @apply right-0 {}
  @apply mx-auto {}
  @apply rounded-full {}
  @apply flex {}
  @apply justify-center {}
  @apply items-center {}
  height: 3.4375rem !important;
  width: 3.4375rem !important;
  box-shadow: 0 2px 10px rgba(54,54,54,0.15) !important;
  transform: scale(1);
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out !important;

  @screen xl {
    bottom: 70px; }

  &::after {
    content: "\f00d" !important;
    @apply font-icon #{!important} {}
    @apply font-light {}
    @apply text-xl {} }

  &:hover {
    @apply text-primary-100 #{!important} {}
    transform: scale(1.1) !important; } }

.lg-actions .lg-prev {
  @apply rounded-full {}
  @apply bg-white {}
  @apply text-primary #{!important} {}
  height: 2.8125rem;
  width: 2.8125rem;
  top: 13px;
  @apply flex {}
  @apply justify-center {}
  @apply items-center {}
  @apply left-0 {}
  box-shadow: 0 2px 10px rgba(54,54,54,0.15);
  transform: scale(1);
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;

  &::after {
    content: "\f053";
    @apply font-icon {}
    @apply font-light {}
    font-size: 1.2rem;
    @apply absolute {}
    top: 13px;
    left: 16px; }

  &:hover {
    transform: scale(1.1); } }

.lg-actions .lg-next {
  @apply rounded-full {}
  @apply bg-white {}
  @apply text-primary #{!important} {}
  top: 13px;
  height: 2.8125rem;
  width: 2.8125rem;
  @apply flex {}
  @apply justify-center {}
  @apply items-center {}
  @apply right-0 {}
  box-shadow: 0 2px 10px rgba(54,54,54,0.15);
  transform: scale(1);
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;

  &::before {
    content: "\f054";
    @apply font-icon {}
    @apply font-light {}
    font-size: 1.2rem;
    @apply absolute {}
    top: 13px;
    right: 16px; }

  &:hover {
    transform: scale(1.1); } }
