////////////////////
// WYSIWYG DEFAULT
////////////////////////////////////////////////////////////////////////////////
.wysiwyg-wrapper {
  h2 {
    @apply font-title {}
    @apply font-bold {}
    @apply text-4xl {}
    @apply mb-2 {} }

  h3 {
    @apply font-title {}
    @apply font-bold {}
    @apply text-3xl {}
    @apply mb-2 {} }

  h4 {
    @apply font-title {}
    @apply font-bold {}
    @apply text-2xl {}
    @apply mb-2 {} }

  h5 {
    @apply font-title {}
    @apply font-bold {}
    @apply text-xl {}
    @apply mb-2 {} }

  ul {
    @apply mb-4 {}
    @extend .list; }

  // CONTENT
  // ---------------------------------------------------------
  p {
    @apply mb-6 {} }

  strong {
    @apply font-bold {} }

  a {
    @extend .link;
    @extend .link-underline;
    @extend .link-primary; }

  // IMAGE
  // ---------------------------------------------------------
  img {
    @apply h-auto {}
    @apply max-w-full {}
    @apply mb-6 {}

    @screen lg {
      @apply mb-12 {} } }

  // VIDEO IFRALE
  // ---------------------------------------------------------
  iframe {
    @apply mb-8 {}
    @apply max-w-full #{!important} {}
    @apply h-auto {} }

  // CITATION
  // ---------------------------------------------------------
  blockquote {
    @apply relative {}
    @apply w-full {}
    @apply bg-gray-100 {}
    @apply font-title {}
    @apply text-lg {}
    @apply text-center {}
    @apply p-12 {}
    @apply my-6 {}

    @screen lg {
      @apply px-20 {}
      @apply py-10 {}
      @apply text-xl {}
      @apply my-8 {} } } }
