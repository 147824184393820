.header-transparent {
  @apply fixed {}
  @apply top-0 {}
  @apply z-header {}
  @apply w-full {}

  .header {
    &__link {
      @extend .link;
      @extend .link-underline;
      @extend .link-white; }

    &__logo-white {
      @apply block {} }

    &__logo-primary {
      @apply hidden {} } } }

.header-white {
  @apply relative {}
  @apply z-header {}
  @apply w-full {}

  .header {
    @apply .bg-white {}

    &__link {
      @extend .link;
      @extend .link-underline;
      @extend .link-primary; }

    &__logo-white {
      @apply hidden {} }

    &__logo-primary {
      @apply block {} } } }
