.img-cover {
  @apply h-full {}
  @apply w-full {}
  object-fit: cover; }


///// IMG CONTAIN
.img-contain {
  @apply h-full {}
  @apply w-full {}
  object-fit: contain; }
