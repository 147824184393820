.footer {
  &__links li {
    &:last-child {
      &::after {
        @apply hidden {} } }

    &::after {
      content: '';
      @apply block {}
      @apply align-middle {}
      @apply h-2 {}
      @apply w-2 {}
      @apply my-4 {}
      @apply mx-auto {}
      @apply rounded-full {}
      @apply bg-white {}

      @screen sm {
        @apply inline-block {}
        @apply mx-3 {}
        @apply my-0 {} } } }

  &__mention-links li {
    &:last-child {
      &::after {
        @apply hidden {} } }

    &::after {
      content: '';
      @apply inline-block {}
      @apply align-middle {}
      height: 1px;
      @apply w-1 {}
      @apply mx-2 {}
      @apply rounded-full {}
      @apply bg-white {}
      @apply opacity-75 {} } } }
