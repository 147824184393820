////////////////////
// FORMS
////////////////////////////////////////////////////////////////////////////////

.form-input {
  @apply bg-white {}
  @apply border-gray {}
  @apply border-2 {}
  @apply text-gray {}
  @apply font-normal {}
  @apply text-base {}
  @apply transition {}
  @apply w-full {}
  @apply rounded-none {}
  @apply appearance-none {}
  @apply h-input {}
  @apply transition {}
  @apply transition-colors {}
  @apply ease-in {}
  @apply duration-200 {}
  @include placeholder {
    @apply text-gray {}
    @apply transition {} }
  &:focus {
    @apply border-primary {}
    @apply shadow-none {}
    @apply outline-none {}
    @include placeholder {
      @apply text-gray {} } }

  &:required {
    @apply border-red {} }

  &:disabled {
    @apply bg-lightgray {}
    @apply border-lightgray {} } }

////////////////////
// FORM INPUT + ICON
////////////////////////////////////////////////////////////////////////////////

.form-icon-wrapper {
  @apply relative {}

  i {
    @apply items-center {}
    @apply text-gray {}
    @apply flex {}
    @apply h-full {}
    @apply justify-center {}
    @apply absolute {}
    @apply top-0 {}
    @apply transition {}
    @apply w-10 {} } }
