////////////////////
// SASS VARIABLES
////////////////////////////////////////////////////////////////////////////////


// FONT AWESOME
// ---------------------------------------------------------
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";

// COLORS
// ---------------------------------------------------------
$primary: #111A68;
$primary-100: #3534bf;
$primary-300: #0a0963;
$primary-border: rgba(#1C2746, 0.35);

$secondary: #fdb827;
$secondary-100: #ffc854;
$secondary-300: #eda100;

$gray: #A0AEC0;
$gray-100: #EDF2F7;
$gray-300: #222222;

$white: #ffffff;
$black: #000000;

/////// USEFULL COLORS
$cyan: #62c5da;
$green: #36d2a1;
$red: #ff6363;
$yellow: #ffaf57;



// TRANSITION
// ---------------------------------------------------------
$transition-duration-default: 350ms;
$transition-timing-default: ease;
$transition-default: $transition-duration-default $transition-timing-default;
$transition-duration-smooth: 600ms;
$transition-timing-smooth: cubic-bezier(.4, 0,.2,1);
$transition-smooth: var(--transition-duration-smooth) var(--transition-timing-smooth);



////////////////////
// CSS CUSTOM PROPERTIES
////////////////////////////////////////////////////////////////////////////////

// https://sass-lang.com/documentation/breaking-changes/css-vars
 :root {
  ///// COLORS
  --primary: #{$primary};
  --primary-100: #{$primary-100};
  --primary-300: #{$primary-300};
  --secondary: #{$secondary};
  --secondary-100: #{$secondary-100};
  --secondary-300: #{$secondary-300};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray};
  --gray-300: #{$gray-300};
  --black: #{$black};
  --white: #{$white};
  --blue: #{$cyan};
  --green: #{$green};
  --red: #{$red};
  --yellow: #{$yellow};
  --app-height: 100vh;

  ///// FONTS
  --font-family-sans: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  --font-family-title: 'Raleway', Georgia, MS Serif, serif;
  --font-family-icon: 'Font Awesome 5 Pro';
  --font-family-icon-brands: 'Font Awesome 5 Brands';

  ///// ANIMATIONS
  --transition-duration-default: #{$transition-duration-default};
  --transition-timing-default: #{$transition-timing-default};
  --transition-default: #{$transition-duration-default} #{$transition-timing-default};
  --transition-duration-smooth:  #{$transition-duration-smooth};
  --transition-timing-smooth:  #{$transition-timing-smooth};
  --transition-smooth:  #{$transition-duration-smooth}  #{$transition-timing-smooth};

  ///// ANIMATIONS
  --transition-duration-default: #{$transition-duration-default};
  --transition-duration-smooth:  #{$transition-duration-smooth};
  --transition-timing-smooth:  #{$transition-timing-smooth}; }




////////////////////
// OVERRIDE VENDORS
////////////////////////////////////////////////////////////////////////////////
$lg-path-fonts: '~lightgallery.js/src/fonts';
$lg-path-images: '~lightgallery.js/src/img';
