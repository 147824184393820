@tailwind base {}
@tailwind components {}
@tailwind utilities {}

@import "helpers/application-variables";
@import "helpers/mixins";
@import "helpers/utils";

@import "fix/navigator";
@import "fix/ios-scroll";
@import "fix/v-cloak";

@import "~swiper/swiper";
@import "~swiper/modules/pagination/pagination";
@import "~swiper/modules/navigation/navigation";

@import "layout/base";
@import "layout/font";
@import "layout/header";
@import "layout/footer";

@import "components/atoms/animations";
@import "components/atoms/pagination";
@import "components/atoms/buttons";
@import "components/atoms/links";
@import "components/atoms/list";
@import "components/atoms/images";
@import "components/atoms/inputs";
@import "components/atoms/tab";
@import "components/molecules/wysiwyg";
@import "components/override-vendors/swiper";
@import "components/override-vendors/lightgallery";

