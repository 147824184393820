////////////////////
// LIST
////////////////////////////////////////////////////////////////////////////////

.list {
  li {
    @apply relative {}
    @apply mb-4 {}
    @apply pl-6 {} }

  li:last-child {
    @apply mb-0 {} }

  li::before {
    content: ' ';
    @apply absolute {}
    @apply left-0 {}
    top: 4px;
    @apply inline-block {}
    min-width: 10px;
    min-height: 10px;
    margin-top: 0.2rem;
    @apply rounded-full {}
    @apply bg-primary {} } }

